import { useState } from 'react';
import { Error, Button } from '../../common';
import { PageContainer, HelpForm } from '../../components';

const AccountSuspended = () => {
  const [showHelpForm, setShowHelpForm] = useState(false);

  const handleNavigate = () => {
    const { HELP_URI } = process.env;
    const win = window.open(HELP_URI, '_blank');
    win.focus();
  };

  const renderHelpForm = () => (
    <HelpForm onClose={() => setShowHelpForm(false)} />
  );

  const renderMessage = () => {
    return (
      <>
        <p>Please contact support to restore your account.</p>
        {showHelpForm && renderHelpForm()}
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Error
          header="Your account has been suspended"
          content={renderMessage()}
        />
        <Button
          sx={{ marginTop: '1rem' }}
          onClick={() => handleNavigate()}
          fullWidth={true}
          variant="outlined"
        >
          Visit Help Center
        </Button>
        <Button
          sx={{ marginTop: '1rem' }}
          onClick={() => setShowHelpForm(true)}
          fullWidth={true}
        >
          Contact Support
        </Button>
      </>
    );
  };

  return (
    <PageContainer title="Account Suspended" pageTitle="Account suspended">
      {renderContent()}
    </PageContainer>
  );
};

export default AccountSuspended;
