// Service worker code in /public/service-worker.js
export const config = {
  onUpdate: () => {
    // Handle in UI layer; event listeners in useVersionCheck, useMaintenanceMode hooks
  },
};

export const register = (config) => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = process.env.SERVICE_WORKER_URI;

      function registerServiceWorker(url) {
        navigator.serviceWorker.register(url)
          .then(registration => {
            registration.onupdatefound = () => {
              const installingWorker = registration.installing;
              if (!installingWorker) return;

              installingWorker.onstatechange = () => {
                switch (installingWorker.state) {
                case 'installed':
                  if (navigator.serviceWorker.controller) {
                    config.onUpdate?.(registration);
                  } else {
                    config.onSuccess?.(registration);
                  }
                  break;
                }
              };
            };
          })
          .catch(error => {
            console.warn('Error during service worker registration:', error);
            setTimeout(() => registerServiceWorker(url), 3000);
          });
      }

      registerServiceWorker(swUrl);
    });
  }
};


export const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.warn(error.message);
      });
  }
};
