import styled from '@emotion/styled';

const Copyright = styled.div`
  display: flex;
  flex: 1;
  font-size: 0.75rem;
  justify-content: center;
  padding: 1rem 1.5rem;
  color: var(--charcoal);
`;

const AuthCopyright = styled.div`
  display: none;
`;

const InlinePortalWrapper = styled.div`
  display: flex;
  flex-shrink: 1;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  &:empty {
    display: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
`;

const Footer = ({ navigation, isAuthenticated }) => {
  const renderCopyright = () => {
    const El = isAuthenticated ? AuthCopyright : Copyright;
    const year = new Date().getFullYear();
    const copyrightNotice = `© ${year} Ordo, Inc. All rights reserved`;
    return <El id="main-footer">{copyrightNotice}</El>;
  };

  const renderFooterPortalEl = () => {
    return <InlinePortalWrapper id="footer-portal" />;
  };

  return (
    <>
      {renderFooterPortalEl()}
      {renderCopyright()}
    </>
  );
};

export default Footer;
