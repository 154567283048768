import { useState } from 'react';
import { Error, Button } from '../../common';
import { PageContainer, HelpForm } from '../../components';

const AccountsEmpty = () => {
  const [showHelpForm, setShowHelpForm] = useState(false);

  const handleNavigate = () => {
    const { HELP_URI } = process.env;
    const win = window.open(HELP_URI, '_blank');
    win.focus();
  };

  const renderHelpForm = () => (
    <HelpForm onClose={() => setShowHelpForm(false)} />
  );

  const renderMessage = () => {
    return (
      <>
        <p>
          This usually means the login you used is for administrative functions
          in our other apps. To place orders for yourself and family, please
          register a new account. You may contact support for assistance.
        </p>
        {showHelpForm && renderHelpForm()}
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Error
          header="No accounts found for this app"
          content={renderMessage()}
        />
        <Button
          sx={{ marginTop: '1rem' }}
          onClick={() => handleNavigate()}
          fullWidth={true}
          variant="outlined"
        >
          Visit Help Center
        </Button>
        <Button
          sx={{ marginTop: '1rem' }}
          onClick={() => setShowHelpForm(true)}
          fullWidth={true}
        >
          Contact Support
        </Button>
      </>
    );
  };

  return (
    <PageContainer title="No Accounts" pageTitle="No accounts found">
      {renderContent()}
    </PageContainer>
  );
};

export default AccountsEmpty;
