import { useState, useEffect } from 'react';

const useMaintenanceMode = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data) {
        switch (event.data.type) {
        case 'MAINTENANCE_MODE_ACTIVE':
          setIsMaintenanceMode(true);
          break;
        case 'MAINTENANCE_MODE_INACTIVE':
          setIsMaintenanceMode(false);
          break;
        default:
          break;
        }
      }
    };

    navigator?.serviceWorker?.addEventListener('message', handleMessage);

    return () => {
      navigator?.serviceWorker?.removeEventListener('message', handleMessage);
    };
  }, []);

  return isMaintenanceMode;
};

export default useMaintenanceMode;
