import { useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import useTemplateContext from '../../hooks/useTemplateContext';
import useViewport from '../../hooks/useViewport';

const image1 = '/public/template-alt/hero/Spaghetti_Meatballs.png';
const image2 =
  '/public/template-alt/hero/Burger_Sliders_with_Cheese_Tots_A.png';
const image3 = '/public/template-alt/hero/Yogurt_Parfait.png';
const image4 = '/public/template-alt/hero/Greek_Salad_No_Chicken.png';
const image5 = '/public/template-alt/hero/Chicken_Shawarma_w_Broccoli.png';
const image6 = '/public/template-alt/hero/Side_of_Mac_and_Cheese.png';
const imageSetA = [image4, image5, image6, image4, image5, image6];
const imageSetB = [image1, image2, image3, image1, image2, image3];

const BaseContainer = styled.div`
  display: grid;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header header'
    'main ${(props) => (props.showHero ? 'hero' : 'main')}';
`;

const HeaderContainer = styled.header`
  grid-column: col-start / span 2;
  grid-area: header;
  padding: 0;
  margin: 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  max-width: 100vw;
  touch-action: none;
  border-bottom: 1px solid var(--gray);
`;

const ContentContainer = styled.main`
  grid-area: main;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 0 1rem 0;
  margin: 0;
  margin-bottom: 4.5rem;
  height: 100%;
  max-height: calc(var(--doc-height) - 4.5rem);
  min-height: calc(var(--doc-height) - 4.5rem);
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  max-width: 100vw;
  box-sizing: border-box;
  align-items: center;
`;

const ContentContainerAlt = styled(ContentContainer)`
  padding: 0;
  height: 100%;
`;

const HeroContainer = styled.aside`
  grid-area: hero;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding-right: 1rem;
  overflow: hidden;
  height: 100%;
`;

const ImageStrip = styled.div`
  height: 100%;
  position: relative;
`;

const ImageStripOffset = styled(ImageStrip)`
  top: -8rem;
`;

const ImageContainer = styled.div`
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center;
`;

const AlternateTemplate = ({ header, footer, children }) => {
  const { isMobile } = useViewport();
  const [state, setState] = useTemplateContext();
  const { showHero = true } = state;

  useEffect(() => {
    return () => {
      setState({ showHero: true });
    };
  }, []);

  const ContentContainerEl = useMemo(() => {
    return !isMobile ? ContentContainer : ContentContainerAlt;
  }, [header, footer, isMobile, showHero]);

  const renderMainContent = () => (
    <ContentContainerEl id="main-content">
      {children || null}
    </ContentContainerEl>
  );

  const renderHero = () => {
    if (isMobile || !showHero) return null;
    return (
      <HeroContainer>
        <ImageStripOffset>
          {[...imageSetA, ...imageSetB.reverse()].map((image, imageIndex) => (
            <ImageContainer
              key={imageIndex}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </ImageStripOffset>
        <ImageStrip>
          {[...imageSetB, ...imageSetA.reverse()].map((image, imageIndex) => (
            <ImageContainer
              key={imageIndex}
              style={{ backgroundImage: `url(${image})` }}
            />
          ))}
        </ImageStrip>
      </HeroContainer>
    );
  };

  const hero = useMemo(() => renderHero(), [isMobile, showHero]);

  return (
    <BaseContainer showHero={showHero && !isMobile}>
      {header && <HeaderContainer>{header}</HeaderContainer>}
      {renderMainContent()}
      {hero}
    </BaseContainer>
  );
};

export default AlternateTemplate;
