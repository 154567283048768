import { useMemo } from 'react';
import styled from '@emotion/styled';

const BaseContainer = styled.div`
  display: grid;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  grid-gap: 0;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
`;

const BaseContainerAlt = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  max-width: 100vw;
`;

const HeaderContainer = styled.header`
  grid-area: header;
  padding: 0;
  margin: 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  max-width: 100vw;
  /* touch-action: none; */
`;

const ContentContainer = styled.main`
  grid-area: main;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  margin-bottom: 4.5rem;
  height: 100%;
  max-height: calc(var(--doc-height) - 4.5rem);
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  max-width: 100vw;
  box-sizing: border-box;
  align-items: center;
`;

const FooterContainer = styled.footer`
  grid-area: footer;
  max-width: 100vw;
`;

const DefaultTemplate = ({ header, footer, children }) => {
  const BaseContainerEl = useMemo(() => {
    return header || footer ? BaseContainer : BaseContainerAlt;
  }, [header, footer]);

  const renderMainContent = () => (
    <ContentContainer id="main-content">{children || null}</ContentContainer>
  );

  return (
    <BaseContainerEl>
      {header && <HeaderContainer>{header}</HeaderContainer>}
      {renderMainContent()}
      {footer && <FooterContainer>{footer}</FooterContainer>}
    </BaseContainerEl>
  );
};

export default DefaultTemplate;
