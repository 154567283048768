import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import useTemplateContext from '../hooks/useTemplateContext';
import DefaultTemplate from './Default';
import AlternateTemplate from './Alternate';
import { OfflineNotice } from '../components';

const BaseLayout = ({
  header,
  footer,
  children,
  isAuthenticated,
  template,
}) => {
  const [{ template: templateName }, setTemplateState] = useTemplateContext();
  const templateProps = useMemo(() => {
    return { header, footer, children, isAuthenticated, templateName };
  }, [header, footer, children, isAuthenticated]);

  const TemplateEl = useMemo(() => {
    if (!template) return DefaultTemplate;
    switch (template) {
      case 'alternate':
        if (templateName === 'alternate') {
          setTemplateState({ template: 'alternate' });
        }
        return AlternateTemplate;
      default:
        if (templateName !== 'default') {
          setTemplateState({ template: 'default' });
        }
        return DefaultTemplate;
    }
  }, [template]);

  return (
    <>
      <Helmet>
        <title>{`${process.env.APP_NAME}`}</title>
      </Helmet>
      <OfflineNotice />
      <TemplateEl {...templateProps} />
    </>
  );
};

export default BaseLayout;
