import { Error, Button, Link } from '../../common';
import { PageContainer } from '../../components';

const MaintenanceMode = () => {
  const handleNavigate = () => {
    const { HELP_URI } = process.env;
    const win = window.open(HELP_URI, '_blank');
    win.focus();
  };

  const renderMessage = () => {
    return (
      <p>
        We're currently tuning up our services to enhance your experience. If
        you need assistance or have any questions, please visit our{' '}
        <Link onClick={handleNavigate}>Help Center</Link>. We'll be back online
        shortly. Thank you for your patience!
      </p>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Error
          header="Maintenance in Progress"
          content={renderMessage()}
          type="warning"
        />
        <Button
          sx={{ marginTop: '1rem' }}
          onClick={() => handleNavigate()}
          fullWidth={true}
          variant="outlined"
        >
          Visit Help Center
        </Button>
      </>
    );
  };

  return (
    <PageContainer title="Maintenance" pageTitle="Maintenance">
      {renderContent()}
    </PageContainer>
  );
};

export default MaintenanceMode;
